<template>
	<div>
		<common-table
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				ref="ct"
				@search="search"
				pageTitle="操作日志"
				:leftConfig="leftConfig"
		>
		</common-table>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/systemManage/operate-log.js';

export default {
	// 操作日志
	mixins: [commonCurd],
	data() {
		return {
			api,
			headers: [
				{text: '用户ID', value: 'userid'},
				{text: '时间', value: 'date'},
				{text: '类型', value: 'type'},
				{text: '操作', value: 'optype'},
				{text: '操作说明', value: 'content', width: ''}
			],
			leftConfig: [
				{type: 'input', label: '输入用户ID', property: 'userid'},
				{type: 'select', label: '选择模块类型', property: 'type', items: []},
				{type: 'singleTime', label: '选择时间', property: 'date'}
			]
		}
	},
	methods: {
		// 监听内容宽度变化
		tableContentFieldWidth() {
			this.headers[4].width = (this.$refs.ct.$el.clientWidth * 0.5) + 'px';
		}
	},
	mounted() {
		this.tableContentFieldWidth();
		this.axios.post(this.select_data, {keyname: 'userlogtype'}).then((res) => {
			if (res.code === this.staticVal.Code.Success) {
				this.leftConfig[1].items = res.data[0].values;
			}
		});
	},
}
</script>

<style scoped lang="scss">

</style>
